import dayjs from 'dayjs';
import Cookies from 'universal-cookie';
import 'dayjs/locale/el';
dayjs.locale('el');
const cookies = {
  el: {
    cookieModelNecessary: [
      {
        id: 1,
        duration: 'session',
        name: 'PHPSESSID',
        description: 'Διατηρεί την κατάσταση της σύνδεσης χρήστη σε όλα τα αιτήματα σελίδας.',
        origin: process.env.appName,
      },
      // {
      //   id: 2,
      //   duration: '12 μήνες',
      //   name: '__zlcmid',
      //   description: 'Χρησιμοποιείται για την άμεση επικοινωνία με το customer support.',
      //   origin: process.env.appName,
      // },
      {
        id: 3,
        duration: '15 ημέρες',
        name: 'token',
        description: 'Διατηρεί την κατάσταση σύνδεσης του χρήστη στον ιστότοπο',
        origin: process.env.appName,
      },
      {
        id: 4,
        duration: 'session',
        name: 'JSESSIONID',
        description: 'Διατηρεί την επιλογή του χρήστη για την χρήση των cookies',
        origin: process.env.appName,
      },
      {
        id: 5,
        duration: 'until deleted by user (delete cache)',
        name: 'productForOrder',
        description: 'Διατηρεί τα προϊόντα στο καλάθι της παραγγελίας του χρήστη',
        origin: process.env.appName,
      },
      {
        id: 6,
        duration: 'until deleted by user (delete cache)',
        name: 'productQuantities',
        description: 'Διατηρεί τις ποσότητες των προϊόντων στο καλάθι της παραγγελίας του χρήστη',
        origin: process.env.appName,
      },
      // {
      //   id: 7,
      //   duration: 'until deleted by user (delete cache)',
      //   name: 'order',
      //   description: 'Διατηρεί τις επιλογές του χρήστη  όσον αφορά την παραγγελίας του',
      //   origin: process.env.appName,
      // },
      // {
      //   id: 8,
      //   duration: 'until deleted by user (delete cache)',
      //   name: 'DeliveryMode',
      //   description: 'Διατηρεί την επιλογή του χρήστη  όσον αφορά την υπηρεσία',
      //   origin: process.env.appName,
      // },
      {
        id: 9,
        duration: 'until deleted by user (delete cache)',
        name: 'PostalCode',
        description:
          'Διατηρεί την επιλογή του χρήστη  όσον αφορά τον ταχυδρομικό του κώδικα για την προβολή των διαθέσιμων υπηρεσιών',
        origin: process.env.appName,
      },
      // {
      //   id: 10,
      //   duration: 'until deleted by user (delete cache)',
      //   name: 'isAddressRegistered',
      //   description: 'Διατηρεί αν ο χρήστης έχει υποβάλει πληροφορίες για τον ταχυδρομικό του κώδικα',
      //   origin: process.env.appName,
      // },
      {
        id: 11,
        duration: 'until deleted by user (delete cache)',
        name: `${process.env.appName}:privacy`,
        description: 'Διατηρεί τις επιλογές του χρήστη για την αποδοχή των cοοkies',
        origin: process.env.appName,
      },
      {
        id: 12,
        duration: 'until deleted by user (delete cache)',
        name: 'Shop',
        description: 'Διατηρεί το κατάστημα που εξηπυρετεί τον χρήστη',
        origin: process.env.appName,
      },
      {
        id: 13,
        duration: 'until deleted by user (delete cache)',
        name: 'loginProvider',
        description: 'Διατηρεί τον τρόπο εισόδου του χρήστη στην εφαρμογή',
        origin: process.env.appName,
      },
      {
        id: 15,
        duration: 'until deleted by user (delete cache)',
        name: 'pickupMethod',
        description: 'Διατηρεί την επιλογή υπηρεσίας παράδοσης/παραλαβής του χρήστη',
        origin: process.env.appName,
      },
      {
        id: 16,
        duration: 'until deleted by user (delete cache)',
        name: 'lastExternalReferrer',
        description:
          'Εντοπίζει τον τρόπο με τον οποίο ο χρήστης έφτασε στον ιστότοπο καταχωρίζοντας την τελευταία του διεύθυνση URL.',
        origin: '	Meta Platforms, Inc.',
      },
      {
        id: 17,
        duration: 'until deleted by user (delete cache)',
        name: 'lastExternalReferrerTime',
        description:
          'Εντοπίζει τον τρόπο με τον οποίο ο χρήστης έφτασε στον ιστότοπο καταχωρίζοντας την τελευταία του διεύθυνση URL.',
        origin: '	Meta Platforms, Inc.',
      },
      {
        id: 18,
        duration: 'until deleted by user (delete cache)',
        name: 'selectedUserAddressIndex',
        description: 'Διατηρεί την επιλογή διεύθυνσης του χρήστη',
        origin: process.env.appName,
      },
      {
        id: 19,
        duration: 'until deleted by user (delete cache)',
        name: 'selectedTakeAwayStoreIndex',
        description: 'Διατηρεί την επιλογή καταστήματος του χρήστη',
        origin: process.env.appName,
      },
      {
        id: 20,
        duration: 'until deleted by user (delete cache)',
        name: 'generalSelectedTimeslot',
        description: 'Διατηρεί την επιλογή timeslot του χρήστη',
        origin: process.env.appName,
      },
      {
        id: 21,
        duration: 'until deleted by user (delete cache)',
        name: 'SelectedShopId',
        description: 'Διατηρεί το id του καταστήματος που εξηπυρετεί τον χρήστη',
        origin: process.env.appName,
      },
      {
        id: 22,
        duration: 'until deleted by user (delete cache)',
        name: 'user',
        description: 'Διατηρεί πληροφορίες για το λογαριασμό του χρήστη',
        origin: process.env.appName,
      },
      {
        id: 23,
        duration: 'until deleted by user (delete cache)',
        name: 'prebook',
        description: 'Διατηρεί πληροφορίες για το προεπιλεγμένο από τον χρήστη timeslot',
        origin: process.env.appName,
      },
      {
        id: 24,
        duration: 'until deleted by user (delete cache)',
        name: 'firstSelectedTimeslot',
        description: 'Διατηρεί την επιλογή timeslot του χρήστη',
        origin: process.env.appName,
      },
      {
        id: 25,
        duration: 'until deleted by user (delete cache)',
        name: 'selectedBundleOffer',
        description: 'Διατηρεί την επιλογή συνδιαστικής προσφοράς από τον χρήστη',
        origin: process.env.appName,
      },
      {
        id: 26,
        duration: 'until deleted by user (delete cache)',
        name: 'cart',
        description: 'Διατηρεί πληροφορίες σχετικά με το καλάθι που έχει ετοιμάσει ο χρήστης',
        origin: process.env.appName,
      },
    ],

    cookiePerformance: [
      {
        id: 1,
        duration: 'session',
        name: '_gat_UA-105613924-4',
        description: 'Καταχωρεί ένα μοναδικό αναγνωριστικό για την εταιρία του ιστότοπου',
        origin: 'Google',
      },
      {
        id: 2,
        duration: '2 ημέρες',
        name: '_gid',
        description:
          'Καταχωρεί ένα μοναδικό αναγνωριστικό που χρησιμοποιείται για τη δημιουργία στατιστικών στοιχείων σχετικά με τον τρόπο με τον οποίο ο επισκέπτης χρησιμοποιεί τον ιστότοπο.',
        origin: 'Google',
      },
      {
        id: 3,
        duration: '24 μήνες',
        name: '_ga',
        description: 'Χρησιμοποιείται από το Google Analytics για να ρυθμίσει την ταχύτητα του αιτήματος',
        origin: 'Google',
      },
      {
        id: 4,
        duration: '6 μήνες',
        name: 'NID',
        description:
          'Το cookie NID περιέχει ένα μοναδικό αναγνωριστικό που χρησιμοποιεί η Google για να θυμάται τις προτιμήσεις σας και άλλες πληροφορίες.',
        origin: 'Google',
      },
      {
        id: 6,
        duration: '24 μήνες',
        name: 'CONSENT',
        description:
          'Η λειτουργία συναίνεσης σάς επιτρέπει να προσαρμόσετε τον τρόπο συμπεριφοράς των ετικετών σας Google με βάση την κατάσταση συναίνεσης των χρηστών σας.',
        origin: 'Google',
      },
      {
        id: 8,
        duration: '30 μέρες',
        name: '1P_JAR',
        description:
          'Αυτά τα cookie χρησιμοποιούνται για τη συλλογή στατιστικών στοιχείων ιστότοπου και την παρακολούθηση των ποσοστών μετατροπών.',
        origin: 'Google',
      },
      {
        id: 9,
        duration: '1 μέρα',
        name: 'DV',
        description:
          'Αυτό το cookie χρησιμοποιείται για την αποθήκευση των προτιμήσεων του χρήστη και άλλων πληροφοριών.',
        origin: 'Google',
      },
    ],
    cookieTargetedAd: process.env.hasContactPigeon
      ? [
          {
            id: 1,
            duration: '12 μήνες',
            name: 'bi',
            description:
              'Χρησιμοποιείται ως συμβολοσειρά αναγνώρισης για την περίοδο σύνδεσης του επισκέπτη. Είναι μια συμβολοσειρά GUID.',
            origin: 'Contact Pigeon',
          },
          {
            id: 2,
            duration: '12 μήνες',
            name: 'cpab',
            description:
              'Αυτό το cookie λειτουργεί προκειμένου να σας παρέχει μία εξατομικευμένη εμπειρία στο Κατάστημα',
            origin: 'Contact Pigeon',
          },
          {
            id: 3,
            duration: '12 μήνες',
            name: 'cp_sessionTime',
            description:
              'Περιέχει την χρονική σήμανση της τελευταίας σελίδας επίσκεψης του επισκέπτη σε χιλιοστά του δευτερολέπτου.',
            origin: 'Contact Pigeon',
          },
          {
            id: 4,
            duration: '12 μήνες',
            name: 'cp_total_cart_value',
            description:
              'Περιέχει την τρέχουσα κατάσταση καλαθιού του επισκέπτη με το τρέχον περιεχόμενο που περιέχει τη συνολική αξία.',
            origin: 'Contact Pigeon',
          },
          {
            id: 5,
            duration: 'Session',
            name: 'cp_shownnotscookie',
            description: 'Κρατάει πληροφορία για εάν ο χρήστης επιθύμει notifications στο browser του',
            origin: 'Contact Pigeon',
          },
          {
            id: 6,
            duration: '12 μήνες',
            name: 'cp_total_cart_items',
            description:
              'Περιέχει την τρέχουσα κατάσταση καλαθιού του επισκέπτη με τον τρέχοντα αριθμό στοιχείων που περιέχει.',
            origin: 'Contact Pigeon',
          },
          {
            id: 7,
            duration: '12 μήνες',
            name: 'IDE',
            description:
              'Τα cookie DoubleClick IDE χρησιμοποιούνται για την προβολή στοχευμένων διαφημίσεων που σχετίζονται με τους χρήστες στον ιστό.',
            origin: 'doubleclick.net',
          },
          {
            id: 8,
            duration: '12 μήνες',
            name: '_gcl_au',
            description:
              'Χρησιμοποιείται από το Google AdSense για πειραματισμό με την αποτελεσματικότητα της διαφήμισης σε ιστότοπους που χρησιμοποιούν τις υπηρεσίες τους.',
            origin: 'Google',
          },
          {
            id: 9,
            duration: '28 μέρες',
            name: '_fbp',
            description: 'Το fbp χρησιμοποιείται για τη διάκριση και την παρακολούθηση των μοναδικών χρηστών σας.',
            origin: 'Facebook',
          },
        ]
      : [
          {
            id: 1,
            duration: '12 μήνες',
            name: 'IDE',
            description:
              'Τα cookie DoubleClick IDE χρησιμοποιούνται για την προβολή στοχευμένων διαφημίσεων που σχετίζονται με τους χρήστες στον ιστό.',
            origin: 'doubleclick.net',
          },
          {
            id: 2,
            duration: '12 μήνες',
            name: '_gcl_au',
            description:
              'Χρησιμοποιείται από το Google AdSense για πειραματισμό με την αποτελεσματικότητα της διαφήμισης σε ιστότοπους που χρησιμοποιούν τις υπηρεσίες τους.',
            origin: 'Google',
          },
          {
            id: 3,
            duration: '28 μέρες',
            name: '_fbp',
            description: 'Το fbp χρησιμοποιείται για τη διάκριση και την παρακολούθηση των μοναδικών χρηστών σας.',
            origin: 'Facebook',
          },
        ],
    cookieFunctional: [
      {
        id: 1,
        duration: 'until deleted by user (delete cache)',
        name: 'sortOption',
        description: 'Διατηρεί την επιλογή του χρήστη  όσον αφορά την προβολή προϊόντων με βάση φίλτρο',
        origin: process.env.appName,
      },
    ],
  },
  en: {
    cookieModelNecessary: [
      {
        id: 1,
        duration: 'session',
        name: 'PHPSESSID',
        description: 'It maintains the state of the user connection across all page requests.',
        origin: process.env.appName,
      },
      // {
      //   id: 2,
      //   duration: '12 μήνες',
      //   name: '__zlcmid',
      //   description: 'Χρησιμοποιείται για την άμεση επικοινωνία με το customer support.',
      //   origin: process.env.appName,
      // },
      {
        id: 3,
        duration: '15 days',
        name: 'token',
        description: 'Maintains the users login status on the website',
        origin: process.env.appName,
      },
      {
        id: 4,
        duration: 'session',
        name: 'JSESSIONID',
        description: 'Maintains the users choice for the use of cookies',
        origin: process.env.appName,
      },
      {
        id: 5,
        duration: 'until deleted by user (delete cache)',
        name: 'productForOrder',
        description: 'Keeps the products in the users order cart',
        origin: process.env.appName,
      },
      {
        id: 6,
        duration: 'until deleted by user (delete cache)',
        name: 'productQuantities',
        description: 'It maintains the quantities of the products in the users order basket',
        origin: process.env.appName,
      },
      // {
      //   id: 7,
      //   duration: 'until deleted by user (delete cache)',
      //   name: 'order',
      //   description: 'Διατηρεί τις επιλογές του χρήστη  όσον αφορά την παραγγελίας του',
      //   origin: process.env.appName,
      // },
      // {
      //   id: 8,
      //   duration: 'until deleted by user (delete cache)',
      //   name: 'DeliveryMode',
      //   description: 'Διατηρεί την επιλογή του χρήστη  όσον αφορά την υπηρεσία',
      //   origin: process.env.appName,
      // },
      {
        id: 9,
        duration: 'until deleted by user (delete cache)',
        name: 'PostalCode',
        description: 'It retains the users choice of zip code to view available services',
        origin: process.env.appName,
      },
      // {
      //   id: 10,
      //   duration: 'until deleted by user (delete cache)',
      //   name: 'isAddressRegistered',
      //   description: 'Διατηρεί αν ο χρήστης έχει υποβάλει πληροφορίες για τον ταχυδρομικό του κώδικα',
      //   origin: process.env.appName,
      // },
      {
        id: 11,
        duration: 'until deleted by user (delete cache)',
        name: `${process.env.appName}:privacy`,
        description: 'It preserves the users choices for accepting cookies',
        origin: process.env.appName,
      },
      {
        id: 12,
        duration: 'until deleted by user (delete cache)',
        name: 'Shop',
        description: 'Maintains the store that serves the user',
        origin: process.env.appName,
      },
      {
        id: 13,
        duration: 'until deleted by user (delete cache)',
        name: 'loginProvider',
        description: 'Maintains how the user entered the application',
        origin: process.env.appName,
      },
      {
        id: 15,
        duration: 'until deleted by user (delete cache)',
        name: 'pickupMethod',
        description: 'Maintains the users choice of delivery/pickup service',
        origin: process.env.appName,
      },
      {
        id: 16,
        duration: 'until deleted by user (delete cache)',
        name: 'lastExternalReferrer',
        description: 'Detects how the user reached the website by registering their last URL-address.	',

        origin: '	Meta Platforms, Inc.',
      },
      {
        id: 17,
        duration: 'until deleted by user (delete cache)',
        name: 'lastExternalReferrerTime',
        description: 'Detects how the user reached the website by registering their last URL-address.	',

        origin: '	Meta Platforms, Inc.',
      },
      {
        id: 18,
        duration: 'until deleted by user (delete cache)',
        name: 'selectedUserAddressIndex',
        description: 'Preserves the users address selection',
        origin: process.env.appName,
      },
      {
        id: 19,
        duration: 'until deleted by user (delete cache)',
        name: 'selectedTakeAwayStoreIndex',
        description: 'Preserves the users shop selection',
        origin: process.env.appName,
      },
      {
        id: 20,
        duration: 'until deleted by user (delete cache)',
        name: 'generalSelectedTimeslot',
        description: 'Preserves the users timeslot selection',
        origin: process.env.appName,
      },
      {
        id: 21,
        duration: 'until deleted by user (delete cache)',
        name: 'SelectedShopId',
        description: 'Keeps the id of the store that serves the user',
        origin: process.env.appName,
      },
      {
        id: 22,
        duration: 'until deleted by user (delete cache)',
        name: 'user',
        description: 'Maintains information about the users account',
        origin: process.env.appName,
      },
      {
        id: 23,
        duration: 'until deleted by user (delete cache)',
        name: 'prebook',
        description: 'Holds information about the users pre booked timeslot',
        origin: process.env.appName,
      },
      {
        id: 24,
        duration: 'until deleted by user (delete cache)',
        name: 'firstSelectedTimeslot',
        description: 'Preserves the users timeslot selection',
        origin: process.env.appName,
      },
      {
        id: 25,
        duration: 'until deleted by user (delete cache)',
        name: 'selectedBundleOffer',
        description: 'Retains the users choice of bundle offer',
        origin: process.env.appName,
      },
      {
        id: 26,
        duration: 'until deleted by user (delete cache)',
        name: 'cart',
        description: 'Maintains information regarding the cart prepared by the user',
        origin: process.env.appName,
      },
    ],

    cookiePerformance: [
      {
        id: 1,
        duration: 'session',
        name: '_gat_UA-105613924-4',
        description: 'Registers a unique identifier for the website company',
        origin: 'Google',
      },
      {
        id: 2,
        duration: '2 days',
        name: '_gid',
        description:
          'Registers a unique identifier that is used to generate statistics about how the visitor uses the website.',
        origin: 'Google',
      },
      {
        id: 3,
        duration: '24 months',
        name: '_ga',
        description: 'Used by Google Analytics to adjust request speed',
        origin: 'Google',
      },
      {
        id: 4,
        duration: '6 months',
        name: 'NID',
        description:
          'The NID cookie contains a unique identifier that Google uses to remember your preferences and other information.',
        origin: 'Google',
      },
      {
        id: 6,
        duration: '24 months',
        name: 'CONSENT',
        description:
          'The consent feature allows you to customize how your tags behave on Google based on the consent status of your users.',
        origin: 'Google',
      },
      {
        id: 8,
        duration: '30 days',
        name: '1P_JAR',
        description: 'These cookies are used to collect website statistics and track conversion rates.',
        origin: 'Google',
      },
      {
        id: 9,
        duration: '1 day',
        name: 'DV',
        description: 'This cookie is used to store user preferences and other information.',
        origin: 'Google',
      },
    ],
    cookieTargetedAd: process.env.hasContactPigeon
      ? [
          {
            id: 1,
            duration: '12 months',
            name: 'bi',
            description: 'Used as the identification string for the visitors session. It is a GUID string.',
            origin: 'Contact Pigeon',
          },
          {
            id: 2,
            duration: '12 months',
            name: 'cpab',
            description: 'This cookie works to provide you with a personalized experience in the Store.',
            origin: 'Contact Pigeon',
          },
          {
            id: 3,
            duration: '12 months',
            name: 'cp_sessionTime',
            description: 'Contains the timestamp of the visitors last visited page in milliseconds.',
            origin: 'Contact Pigeon',
          },
          {
            id: 4,
            duration: '12 months',
            name: 'cp_total_cart_value',
            description:
              'Contains the current cart state of the visitor with the current content containing the total value.',
            origin: 'Contact Pigeon',
          },
          {
            id: 5,
            duration: 'Session',
            name: 'cp_shownnotscookie',
            description: 'It keeps information about whether the user wants notifications in his browser',
            origin: 'Contact Pigeon',
          },
          {
            id: 6,
            duration: '12 months',
            name: 'cp_total_cart_items',
            description: 'Contains the visitors current cart status with the current number of items it contains.',
            origin: 'Contact Pigeon',
          },
          {
            id: 7,
            duration: '12 months',
            name: 'IDE',
            description: 'DoubleClick IDE cookies are used to serve targeted ads relevant to users on the web.',
            origin: 'doubleclick.net',
          },
          {
            id: 8,
            duration: '12 months',
            name: '_gcl_au',
            description:
              'Used by Google AdSense to experiment with the effectiveness of advertising on websites using their services.',
            origin: 'Google',
          },
          {
            id: 9,
            duration: '28 days',
            name: '_fbp',
            description: 'fbp is used to distinguish and track unique users.',
            origin: 'Facebook',
          },
        ]
      : [
          {
            id: 1,
            duration: '12 months',
            name: 'IDE',
            description: 'DoubleClick IDE cookies are used to serve targeted ads relevant to users on the web.',
            origin: 'doubleclick.net',
          },
          {
            id: 2,
            duration: '12 months',
            name: '_gcl_au',
            description:
              'Used by Google AdSense to experiment with the effectiveness of advertising on websites using their services.',
            origin: 'Google',
          },
          {
            id: 3,
            duration: '28 days',
            name: '_fbp',
            description: 'fbp is used to distinguish and track unique users.',
            origin: 'Facebook',
          },
        ],
    cookieFunctional: [
      {
        id: 1,
        duration: 'until deleted by user (delete cache)',
        name: 'sortOption',
        description: 'It preserves the users choice of displaying products based on a filter',
        origin: process.env.appName,
      },
    ],
  },
  cn: {
    cookieModelNecessary: [
      {
        id: 1,
        duration: '会话',
        name: 'PHPSESSID',
        description: '保持用户会话状态，以便在所有页面请求中维持登录状态。',
        origin: process.env.appName,
      },
      {
        id: 3,
        duration: '15天',
        name: 'token',
        description: '保持用户在网站上的登录状态',
        origin: process.env.appName,
      },
      {
        id: 4,
        duration: '会话',
        name: 'JSESSIONID',
        description: '保持用户对 Cookie 使用的选择',
        origin: process.env.appName,
      },
      {
        id: 5,
        duration: '直到用户删除（清除缓存）',
        name: 'productForOrder',
        description: '保持用户购物车中的产品',
        origin: process.env.appName,
      },
      {
        id: 6,
        duration: '直到用户删除（清除缓存）',
        name: 'productQuantities',
        description: '保持用户购物车中的产品数量',
        origin: process.env.appName,
      },
      {
        id: 9,
        duration: '直到用户删除（清除缓存）',
        name: 'PostalCode',
        description: '保留用户选择的邮政编码，以便查看可用的服务',
        origin: process.env.appName,
      },
      {
        id: 11,
        duration: '直到用户删除（清除缓存）',
        name: `${process.env.appName}:privacy`,
        description: '保留用户接受 Cookie 的选择',
        origin: process.env.appName,
      },
      {
        id: 12,
        duration: '直到用户删除（清除缓存）',
        name: 'Shop',
        description: '保持用户所选择的服务商店',
        origin: process.env.appName,
      },
      {
        id: 13,
        duration: '直到用户删除（清除缓存）',
        name: 'loginProvider',
        description: '保持用户的登录方式',
        origin: process.env.appName,
      },
      {
        id: 15,
        duration: '直到用户删除（清除缓存）',
        name: 'pickupMethod',
        description: '保持用户的配送/取货方式选择',
        origin: process.env.appName,
      },
      {
        id: 16,
        duration: '直到用户删除（清除缓存）',
        name: 'lastExternalReferrer',
        description: '检测用户如何到达网站，记录其最后的 URL 地址',
        origin: 'Meta Platforms, Inc.',
      },
      {
        id: 17,
        duration: '直到用户删除（清除缓存）',
        name: 'lastExternalReferrerTime',
        description: '检测用户如何到达网站，记录其最后的 URL 地址',
        origin: 'Meta Platforms, Inc.',
      },
      {
        id: 26,
        duration: '直到用户删除（清除缓存）',
        name: 'cart',
        description: '保持用户准备的购物车信息',
        origin: process.env.appName,
      },
    ],

    cookiePerformance: [
      {
        id: 1,
        duration: '会话',
        name: '_gat_UA-105613924-4',
        description: '为网站公司注册一个唯一的标识符',
        origin: 'Google',
      },
      {
        id: 2,
        duration: '2天',
        name: '_gid',
        description: '注册唯一标识符，用于生成关于访问者如何使用网站的统计数据。',
        origin: 'Google',
      },
      {
        id: 3,
        duration: '24个月',
        name: '_ga',
        description: '由 Google Analytics 用于调整请求速度',
        origin: 'Google',
      },
      {
        id: 4,
        duration: '6个月',
        name: 'NID',
        description: 'NID Cookie 包含 Google 用于记住您的偏好和其他信息的唯一标识符。',
        origin: 'Google',
      },
      {
        id: 6,
        duration: '24个月',
        name: 'CONSENT',
        description: '同意功能允许您根据用户的同意状态自定义 Google 标签的行为。',
        origin: 'Google',
      },
      {
        id: 8,
        duration: '30天',
        name: '1P_JAR',
        description: '这些 Cookie 用于收集网站统计数据并跟踪转化率。',
        origin: 'Google',
      },
      {
        id: 9,
        duration: '1天',
        name: 'DV',
        description: '此 Cookie 用于存储用户偏好和其他信息。',
        origin: 'Google',
      },
    ],

    cookieTargetedAd: process.env.hasContactPigeon
      ? [
          {
            id: 1,
            duration: '12个月',
            name: 'bi',
            description: '用于识别访问者会话的唯一字符串。它是一个 GUID 字符串。',
            origin: 'Contact Pigeon',
          },
          {
            id: 2,
            duration: '12个月',
            name: 'cpab',
            description: '此 Cookie 用于提供个性化的在线购物体验。',
            origin: 'Contact Pigeon',
          },
          {
            id: 7,
            duration: '12个月',
            name: 'IDE',
            description: 'DoubleClick IDE Cookie 用于向用户投放相关的个性化广告。',
            origin: 'doubleclick.net',
          },
          {
            id: 8,
            duration: '12个月',
            name: '_gcl_au',
            description: '由 Google AdSense 用于测试广告的有效性。',
            origin: 'Google',
          },
          {
            id: 9,
            duration: '28天',
            name: '_fbp',
            description: '_fbp 用于区分和跟踪唯一用户。',
            origin: 'Facebook',
          },
        ]
      : [
          {
            id: 1,
            duration: '12个月',
            name: 'IDE',
            description: 'DoubleClick IDE Cookie 用于向用户投放相关的个性化广告。',
            origin: 'doubleclick.net',
          },
          {
            id: 2,
            duration: '12个月',
            name: '_gcl_au',
            description: '由 Google AdSense 用于测试广告的有效性。',
            origin: 'Google',
          },
          {
            id: 3,
            duration: '28天',
            name: '_fbp',
            description: '_fbp 用于区分和跟踪唯一用户。',
            origin: 'Facebook',
          },
        ],

    cookieFunctional: [
      {
        id: 1,
        duration: '直到用户删除（清除缓存）',
        name: 'sortOption',
        description: '保留用户选择的产品排序方式',
        origin: process.env.appName,
      },
    ],
  },
};

export default cookies;

export function createTokenCookie(authToken) {
  const cookies = new Cookies();

  const cookieExpirationDate = dayjs().add(15, 'day').toDate();
  cookies.set('token', `${authToken}`, {
    expires: cookieExpirationDate,
    path: '/',
  });
}
